





























import { api } from "@/api/api";
import { ApiGetShortCurriculumDto } from "@/api/generated/Api";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { getForApprovalStatus } from "@/shared/helpers/curriculumHelpers";
import { formatRelative } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { formatDate } from "@/shared/helpers/dateHelpers";

export default defineComponent({
  name: "PlansForApprovalPage",
  components: {
    BaseTableFiltered,
    BaseTooltipIconButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore<StoreState>();
    const curriculumForApproval = ref<ApiGetShortCurriculumDto[]>([]);
    const search = ref<string>("");

    const openImplementationPlanPage = (value: ApiGetShortCurriculumDto) => {
      router.push(`/studieplaner/details/gjennomforing/${value.id}`);
    };

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const statusApproval = await getForApprovalStatus(store);
        if (!statusApproval?.id) {
          openNotification(store, NotificationItemType.Error, "Finner ikke status 'Til godkjenning' i courseStatuses");
          return;
        }

        const response = await api.curriculum.getCurriculaAsync({ StatusIds: [statusApproval.id] });
        curriculumForApproval.value = response.data;
      });
    });

    return {
      curriculumForApproval,
      headers,
      search,
      formatDate,
      formatRelative,
      openImplementationPlanPage,
    };
  },
});

const headers = [
  { text: "Navn", value: "name" },
  {
    text: "Medlemsorganisasjon",
    value: "orgName",
  },
  { text: "Hovedområde", value: "mainCourseName" },
  { text: "Kursområde", value: "subCourseName" },
  {
    text: "Status",
    value: "curriculumStatusName",
  },
  {
    text: "Opprettet av",
    value: "insertedByUsername",
  },
  {
    text: "Sendt til godkjenning",
    value: "inserted",
  },
  {
    text: "Opprettet dato",
    value: "updated",
  },
];
