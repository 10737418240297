





















































import { api, dashboardApi } from "@/api/api";
import { ApiGetWorkingHourDto } from "@/api/generated/Api";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { WorkingHourStatusEnum } from "@/shared/enums/WorkingHourStatus.enum";
import { WorkingHourUserIdFilter } from "@/shared/enums/workingHourUserIdFilter.enum";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper, onParamChange } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, ref } from "@vue/composition-api";
import { getDashboardUserId } from "@/shared/helpers/dashboardHelpers";

export default defineComponent({
  components: { BaseTableFiltered, BaseTooltipIconButton },
  name: "PendingWorkingHoursPage",
  setup() {
    const router = useRouter();
    const store = useStore<StoreState>();
    const workingHoursPendingApproval = ref<ApiGetWorkingHourDto[]>();
    const search = ref("");
    const currentEmployeeId = ref<number>();

    const displayWorkingHourEntry = (workingHourEntry: ApiGetWorkingHourDto) => {
      router.push({
        name: SingleCourseRouteNames.CourseWorkingHourDetails,
        params: {
          id: workingHourEntry.courseId.toString(),
          hourEntryId: workingHourEntry.id.toString(),
        },
      });
    };

    const approveWorkingHourEntry = async (workingHourEntryId: number) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.economy.approveWorkingHourAsync([workingHourEntryId]);
        openNotification(store, NotificationItemType.Success, "Arbeidstimer godkjent");
        await loadWorkingHours();
      });
    };

    const loadWorkingHours = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        currentEmployeeId.value = await getDashboardUserId();
        workingHoursPendingApproval.value = (
          await dashboardApi.economy.getWorkingHoursAsync({
            WorkingHourUserIdFilter: WorkingHourUserIdFilter.SupervisorOnly,
            Statuses: [WorkingHourStatusEnum.NotApproved],
            UserId: currentEmployeeId.value,
          })
        ).data;
      });
    };

    onParamChange(loadWorkingHours);

    return {
      search,
      headers,
      DashboardRouteNames,
      workingHoursPendingApproval,
      displayWorkingHourEntry,
      approveWorkingHourEntry,
      formatLocalizedDate,
    };
  },
});

const headers = [
  { text: "Kurs-ID", value: "courseId" },
  { text: "Kursnavn", value: "courseName" },
  { text: "Navn på ressurs", value: "userFullName" },
  { text: "Type timer", value: "costTypeName" },
  { text: "Dato", value: "date" },
  { text: "Status", value: "statusText" },
  { text: "Handlinger", value: "actions" },
];
